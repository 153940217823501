import { Container } from '@/components/ui/container';
import { Polygon } from '@/public/assets/svges/Polygon';
import { YouTubeIcon } from '@/public/assets/svges/YouTubeIcon';
import { Fade } from 'react-reveal';
import { useContext } from 'react';
import { GlobalDatasContext } from '@/context/globalDatas';
import { LINKS } from '@/constants/links';
import { Video } from './Video';
import Flip from 'react-reveal/Flip';
import Link from 'next/link';
import classes from './index.module.css';

const AboutCompany = () => {
    const { home: { data } } = useContext(GlobalDatasContext);

    return (
        <section className={classes.aboutCompanySection}>
            <Container>
                <Flip left>
                    <h2 className={classes.subTitle}>
                        {data?.videoTitle}
                    </h2>
                </Flip>
                <div className={classes.wrapperSections}>
                    <Fade left>
                        <div className={classes.video}>
                            <Video id='bezmTmpG00U' />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className={classes.seccondSection}>
                            <div className={classes.contentAboutSubscibe}>
                                <i className={classes.firstPolygon}>
                                    <Polygon />
                                </i>
                                <p>
                                    <Polygon rotate={-90}/>
                                    <span> <b>Don’t</b> Forget to <b>Like and</b> Subscribe.</span>
                                    <Polygon rotate={-70} />
                                </p>
                                <div className={classes.wrapperLink}>
                                    <Link
                                        target='_blank'
                                        href={data?.videoLink || LINKS.to_youtube.url}
                                        aria-label={LINKS.to_youtube.ariaLabel}
                                        className={classes.linkToYouTube}
                                    >
                                        <YouTubeIcon />
                                        <span>SUBSCRIBE</span>
                                    </Link>
                                </div>
                                { data?.videoDescription &&
                                    <p dangerouslySetInnerHTML={{ __html: data.videoDescription }}/>
                                }
                            </div>
                        </div>
                    </Fade>
                </div>
            </Container>
        </section>
    );
};

export { AboutCompany };