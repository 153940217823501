import { Container } from '@/components/ui/container';
import { sliceDangerousHTMLString } from '@/helper/strings';
import { GlobalDatasContext } from '@/context/globalDatas';
import { useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Flip from 'react-reveal/Flip';
import classes from './index.module.css';

const images = [
    '/assets/images/crashCarIcon.png',
    '/assets/images/takeCarIcon.png',
    '/assets/images/exoticCarIcon.png',
    '/assets/images/doortoDoorIcon.png'
]

const Services: React.FC = () => {
    const { transportServices: { data } } = useContext(GlobalDatasContext);

    return (
        <section className={classes.servicesSection}>
            <Container>
                <div className={classes.wrapperServices}>
                    <div className={classes.descriptionWrapper}>
                        <h2 className={classes.subTitle}>{data?.transportServicesTitle}</h2>
                        <div className={classes.description}>
                            { data?.transportServicesDescription && 
                                <p dangerouslySetInnerHTML={sliceDangerousHTMLString({ __html: data.transportServicesDescription }, 2000)}/>
                            }
                        </div>
                    </div>
                    <div className={classes.wrapperBlocks}>
                        <div className={classes.twoServiceBlock}>
                            { data?.transportServiceCards && data.transportServiceCards.slice(0, 2).map((service, index) => (
                                <Flip left key={index}>
                                    <div className={classes.serviceBlock}>
                                        <h4 className={classes.serviceTitle}>{service.title}</h4>
                                        { service?.text &&
                                            <p className={classes.serviceDescription} dangerouslySetInnerHTML={{ __html: service.text }}/>
                                        }
                                        <div className={classes.wrapperLink}>
                                            <Link href={'/'+service.link} className={classes.serviceLink}>{service.btnText}</Link>
                                            <div className={classes.wrapperCarImg}>
                                                <Image
                                                    src={images[index]}
                                                    alt="hero background image"
                                                    width={100}
                                                    height={60}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Flip>
                            ))}
                        </div>
                        <div className={classes.twoServiceBlock}>
                            { data?.transportServiceCards && data.transportServiceCards.slice(2, data?.transportServiceCards.length).map((service, index) => (
                                <Flip left key={index}>
                                    <div className={classes.serviceBlock}>
                                        <h4 className={classes.serviceTitle}>{service.title}</h4>
                                        { service?.text &&
                                            <p className={classes.serviceDescription} dangerouslySetInnerHTML={{ __html: service.text }}/>
                                        }
                                        <div className={classes.wrapperLink}>
                                            <Link href={'/'+service.link} className={classes.serviceLink}>{service.btnText}</Link>
                                            <div className={classes.wrapperCarImg}>
                                                <Image
                                                    src={images[index + 2]}
                                                    alt="hero background image"
                                                    width={100}
                                                    height={60}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Flip>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export { Services };