import { Container } from '@/components/ui/container';
import { StarIcon } from '@/public/assets/svges/StarIcon';
import { ArrowIcon } from '@/public/assets/svges/ArrowIcon';
import { GlobalDatasContext } from '@/context/globalDatas';
import { useContext } from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import Link from 'next/link';
import Image from 'next/image';
import classes from './index.module.css';

const Sosiale: React.FC = () => {
    const { width } = useWindowSize();
    
    const { home: { data } } = useContext(GlobalDatasContext);

    if(Number(width) <= 991) return null;

    return (
        <section className={classes.sosialeSection}>
            <Container>
                <h2 className={classes.subTitle}>{data?.linksReviewTitle}</h2>
                <div className={classes.sosialGroup}>
                    { data?.linksReviesSocial && data.linksReviesSocial.slice(0, 3).map(sosial => (
                        <div className={classes.sosial} key={sosial.id}>
                            <div className={classes.icons}>
                                <Link href={sosial.link}>
                                    <Image
                                        src={sosial.image}
                                        alt="social icon"
                                        width={62}
                                        height={62}
                                        style={{ aspectRatio: '62/62' }}
                                    />
                                </Link>
                                {new Array(5).fill(1).map((_, i) => (
                                    <StarIcon key={i}/>
                                ))}
                            </div>
                            <p className={classes.sosialName}>{sosial.name}</p>
                            <Link href={sosial.link} className={classes.seeMore}>See More <ArrowIcon rotate={-90} color='#F58020'/> </Link>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
};

export { Sosiale };