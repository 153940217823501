import { Container } from '@/components/ui/container';
import { ButtonUI } from '@/components/ui/ButtonUI';
import { GlobalDatasContext } from '@/context/globalDatas';
import { useContext } from 'react';
import { LINKS_FROM_MENU_TITLES } from '@/constants/words';
import Fade from 'react-reveal';
import Flip from 'react-reveal/Flip';
import Link from 'next/link';
import Image from 'next/image';
import classes from './index.module.css';

const CalculateFee: React.FC = () => {
    const {
        menus: { data: menuData },
        calculation: { data: calculationData }
    } = useContext(GlobalDatasContext);

    return (
        <section className={classes.calculateFeeSection}>
            <Container>
                <Flip left>
                    <h2 className={classes.subTitle}>{calculationData?.calculationTitle}</h2>
                </Flip>
                <div className={classes.wrapperCategories}>
                    { calculationData?.calculationBlocks.map((calculationBlock, index) => (
                        <Flip
                            bottom={index > 0 && index < calculationData?.calculationBlocks.length - 1}
                            right={index === calculationData?.calculationBlocks.length - 1}
                            left={index === 0}
                            key={calculationBlock.id}
                        >
                            <div key={index} className={classes.categoryCard}>
                                <Image
                                    src={calculationBlock.image}
                                    alt="hero calculation clock image"
                                    width={50}
                                    height={50}
                                />
                                <p className={classes.cardText}>{calculationBlock.text}</p>
                            </div>
                        </Flip>
                    ))}
                </div>
                <Fade bottom>
                    <Link href={menuData?.contactInfo?.[LINKS_FROM_MENU_TITLES.fAQs]?.url || '/'}>
                        <ButtonUI
                            classN='animationFromTransparent'
                            text={menuData?.contactInfo?.[LINKS_FROM_MENU_TITLES.fAQs]?.title || ''}
                            width={140}
                        />
                    </Link>
                </Fade>
            </Container>
        </section>
    );
};

export { CalculateFee };