import { useState } from 'react';
import { Container } from '@/components/ui/container';
import { ArticleBlock } from './ArticleBlock';
import { formatDate } from '@/helper/time';
import Link from 'next/link';
import Image from 'next/image';
import Fade from 'react-reveal';
import Flip from 'react-reveal/Flip';

import classes from './index.module.css';

interface IProps {
    data: {
        blogs: Array<Record<string, any>>
        news: Array<Record<string, any>>
    };
};

const Articles: React.FC<IProps> = ({ data }) => {
    const [ imageError, setImageError ] = useState<{
        blogImage: boolean;
        newImage: boolean;
    }>({
        blogImage: false,
        newImage: false,
    });

    return (
        <section className={classes.articles}>
            <Container>
                <h2 className={classes.subTitle}>Our Articles</h2>
                <div className={classes.wrapperArticle}>
                    { data?.blogs.length && (
                        <div className={classes.blogsSection}>
                            <Link href={'/' + data?.blogs[0].slug + '/'}>
                                <Image
                                    src={imageError.blogImage ? '/assets/images/cars.png' : data?.blogs[0].image}
                                    className={classes.blogsSectionImg}
                                    alt="cars image"
                                    width={440}
                                    height={340}
                                    onError={() => setImageError({... imageError, blogImage: true})}
                                />
                            </Link>
                            <div className={classes.wrapperArticleBlock}>
                                <ArticleBlock
                                    articleName='Blogs'
                                    articleDescription={data?.blogs[0].title}
                                    date={formatDate(data?.blogs[0]?.created_at?.value)}
                                    linkReadMore={'/' + data?.blogs[0].slug}
                                />
                            </div>
                        </div>
                    )}
                    { data?.news.length && (
                        <div className={classes.newsSection}>
                            <Link href={'/' + data?.news[0].slug}>
                                <Image
                                    src={imageError.newImage ? '/assets/images/messagesBigIcon.png' : data?.news[0].image}
                                    className={classes.newsSectionImg}
                                    alt="cars image"
                                    width={440}
                                    height={340}
                                    onError={() => setImageError({... imageError, newImage: true})}
                                />
                            </Link>
                            <div className={classes.wrapperArticleBlock}>
                                <ArticleBlock
                                    articleName='News'
                                    articleDescription={data?.news[0].title}
                                    date={formatDate(data?.news[0]?.created_at?.value)}
                                    linkReadMore={'/' + data?.news[0].slug}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </section>
    );
};

export { Articles };