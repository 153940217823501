export const metaTags = {
    home: [
        <title key={1}>Home | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Home"
        ></meta>
    ],
    whyUs: [
        <title key={1}>Why us | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Why us"
        ></meta>
    ],
    quote: [
        <title key={1}>Quote | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Quote"
        ></meta>
    ],
    news: [
        <title key={1}>News | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website News"
        ></meta>
    ],
    history: [
        <title key={1}>History | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website History"
        ></meta>
    ],
    faqs: [
        <title key={1}>Faqs | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Faqs"
        ></meta>
    ],
    contact: [
        <title key={1}>Contact-us | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Contact-us"
        ></meta>
    ],
    cheapTransport: [
        <title key={1}>Cheap-transport | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Cheap-transport"
        ></meta>
    ],
    blogs: [
        <title key={1}>Blogs | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Blogs"
        ></meta>
    ],
    auction: [
        <title key={1}>Auction | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Auction"
        ></meta>
    ],
    terms: [
        <title key={1}>Terms and conditions | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Terms and conditions"
        ></meta>
    ],
    howItWorks: [
        <title key={1}>Terms and conditions | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website Terms and conditions"
        ></meta>
    ],
    notFound: [
        <title key={1}>NotFound | Columbus auto transport CRM</title>,
        <meta key={2}
            property="og:title"
            data-hid="og:title"
            data-n-head="ssr"
            content="Columbus auto transport Website NotFound"
        ></meta>
    ]
};