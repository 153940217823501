import { Articles } from '@/components/home/Articles';
import { Services } from '@/components/home/Services';
import { Sosiale } from '@/components/home/Sosiale';
import { AboutCompany } from '@/components/home/aboutCompany';
import { AboutTransport } from '@/components/home/aboutTransport';
import { CalculateFee } from '@/components/home/calculateFee';
import { Hero } from '@/components/home/hero';
import { Review } from '@/components/home/review';
import { LoadingUI } from '@/components/ui/LoadingUI';
import { metaTags } from '@/constants/metaTags';
import { getCalculations, getHome, getLatestArticles, getTransportServices } from '@/constants/service';
import { GlobalDatasContext } from '@/context/globalDatas';
import { Fragment, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Redirect } from '@/components/Redirect';
import { setHomeDataAction, setHomeErrorAction } from '@/context/globalDatas/reducer/actions/home';
import { setLatestArticlesDataAction } from '@/context/globalDatas/reducer/actions/latestArticles';
import { setColculationDataAction } from '@/context/globalDatas/reducer/actions/colculation';
import { setTransportServiceDataAction } from '@/context/globalDatas/reducer/actions/transportService';
import Head from 'next/head';
import useWindowSize from '@/hooks/useWindowSize';

export default function Home() {
  const {
    dispatch,
    home: { data, error },
    calculation,
    transportServices
  } = useContext(GlobalDatasContext);
  const { width } = useWindowSize();

  const { isLoading: isLoadingHomeData } = useQuery('home', getHome(), {
    onSuccess: data => dispatch(setHomeDataAction(data)),
    onError,
    enabled: Boolean(!data)
  });

  const { isLoading: isLoadingLatestArticles } = useQuery('latestArticles', getLatestArticles(), {
    onSuccess: data => dispatch(setLatestArticlesDataAction(data)),
    onError,
    enabled: Boolean(data && data.latestArticles === undefined)
  });

  const { isLoading: isLoadingCalculation } = useQuery('calculation', getCalculations(), {
    onSuccess: data => dispatch(setColculationDataAction(data)),
    onError,
    enabled: Boolean(calculation.data === null)
  });

  const { isLoading: isLoadingTransportServices } = useQuery('transport-services', getTransportServices(), {
    onSuccess: data => dispatch(setTransportServiceDataAction(data)),
    onError,
    enabled: Boolean(transportServices.data === null)
  });

  const isMobile = useMemo(() => Number(width) <= 768, [width]);

  function onError (error: any) {
    dispatch(setHomeErrorAction(error))
  };

  if(error) return <Redirect to='404'/>;

  if(
    isLoadingHomeData ||
    isLoadingLatestArticles ||
    isLoadingCalculation ||
    isLoadingTransportServices ||
    !data
  ) return <LoadingUI type='fullPage'/>;

  return (
    <Fragment>
      <Head>{metaTags.home}</Head>
      <Hero />
      <Review />
      <CalculateFee />
      <AboutTransport />
      <AboutCompany />
      <Sosiale />
      <Services />
      {!isMobile && <Articles data={data.latestArticles!}/>}
    </Fragment>
  );
};
