import Image from "next/image";
import Fade from 'react-reveal';
import { useContext } from "react";
import { Container } from '@/components/ui/container';
import { GlobalDatasContext } from "@/context/globalDatas";

import classes from './index.module.css';

const AboutTransport: React.FC = () => {
    const { home: { data } } = useContext(GlobalDatasContext);

    return (
        <section className={classes.aboutTransportSection}>
            <Container>
                <div className={classes.wrapperSections}>
                    <Fade left>
                        <div className={classes.transportContent}>
                            <h3 className={classes.subLink}>About Us</h3>
                            <h2 className={classes.subTitle}>{data?.autoTransportTitle}</h2>
                            <div className={classes.description}>
                                <p className={classes.firstDescription}>
                                    {data?.autoTransportDescription}
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className={classes.images}>
                            <Image
                                src={data?.autoTransportImage1 || '/assets/images/big-truck.png'}
                                alt="big truck image"
                                className={classes.imageBigTruck}
                                width={999}
                                height={999}
                            />
                            <Image
                                src={data?.autoTransportImage2 || "/assets/images/worksTeam.png"}
                                alt="works Team image"
                                className={classes.worksTeam}
                                width={3840}
                                height={999}
                            />
                        </div>
                    </Fade>

                </div>
            </Container>
        </section>
    );
};

export { AboutTransport };