import { Container } from '@/components/ui/container';
import { StarIcon } from '@/public/assets/svges/StarIcon';
import { useContext } from 'react';
import { GlobalDatasContext } from '@/context/globalDatas';
import { ReviewsFormContext } from '@/context/reviewsForm';
import { useQuery } from 'react-query';
import { getReviews } from '@/constants/service';
import { setReviewsDataAction } from '@/context/globalDatas/reducer/actions/reviews';
import { LoadingUI } from '@/components/ui/LoadingUI';
import useWindowSize from '@/hooks/useWindowSize';
import Fade from 'react-reveal';
import Flip from 'react-reveal/Flip';
import classes from './index.module.css';
import Link from 'next/link';

const Review = () => {
    const { width } = useWindowSize();
    const { dispatch, home: { data }, reviews: { data: reviewsData } } = useContext(GlobalDatasContext);
    const { openReviewsForm } = useContext(ReviewsFormContext);

    const { isLoading } = useQuery('reviews', getReviews(), {
        onSuccess: data => dispatch(setReviewsDataAction(data)),
        enabled: Boolean(!reviewsData)
    });
    
    if(isLoading) return <LoadingUI type='fullPage' />;

    return (
        <section className={classes.reviewSection}>
            <Container>
                <Flip left>
                    <h2 className={classes.subTitle}>{data?.reviewTitle}</h2>
                </Flip>
                <div className={classes.reviewContent}>
                    <Fade left>
                        <div className={classes.descriptionWrapper}>
                            <h3 className={classes.titleOfDescription}>{data?.reviewSubTitle}</h3>
                            { data?.reviewDescription &&
                                <p className={classes.description} dangerouslySetInnerHTML={{ __html: data.reviewDescription }}/>
                            }
                        </div>
                    </Fade>
                    <div className={classes.reviewContentSecondeBlock}>
                        <Fade bottom>
                            <div className={classes.estimate} >
                                <p className={classes.estimateDiscuss}>{reviewsData?.reviewPercent} / 5</p>
                                <p className={classes.wrapperStars}>
                                    { new Array(5).fill('').map((_, i) => (
                                        <StarIcon
                                            key={i}
                                            {...((5 - Math.floor(5 / (reviewsData?.reviewPercent || 0))) === i && { precent: (5 - (reviewsData?.reviewPercent || 0)) * 100})}
                                        />
                                    ))}
                                </p>
                                <p className={classes.reviewQuantity}>{reviewsData?.feedbacksLenght} Review</p>
                            </div>
                        </Fade>
                        { Boolean(Number(width) > 1024) && (
                            <Fade bottom>
                                <div className={classes.presentation}>
                                    { reviewsData?.starCounts.sort((a, b) => b.id - a.id).map(({ id, percentCount, percent }) => (
                                        <div className={classes.percentList} key={id}>
                                            <span className={classes.percentId}>{id}</span>
                                            <StarIcon />
                                            <div className={classes.percentWrapper}>
                                                <span
                                                    className={classes.percentActive}
                                                    style={{width: `${percent}%`}}
                                                />
                                            </div>
                                            <span className={classes.row}>|</span>
                                            <span className={classes.percent}>{percentCount}</span>
                                        </div>
                                    ))}
                                </div>
                            </Fade>
                        )}
                        <Fade right>
                            <div className={classes.buttons}>
                                <button
                                    className={classes.feedbackBtn}
                                    onClick={openReviewsForm}
                                >
                                    Give us your feedback
                                </button>
                                <Link href={'/customer-reviews'} className={classes.animationFromTransparent}>
                                    Read More
                                </Link>
                            </div>
                        </Fade>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export { Review };